import React from 'react'
import { Typography } from '@mui/material'
import { useStyles } from './styles'
import Navbar from '../Navbar'
import HamburgerMenu from '../HamburgerMenu'
import { useScroll, useWindowSize } from '../../utils'

const Header = (props) => {
    const { scrollTo, elements } = useScroll()
    const { isDesktop } = useWindowSize()
    const classes = useStyles()

    return (
        <div className={classes.header}>
            <Typography variant='h2' className={classes.title} onClick={() => scrollTo(elements.home)}>Jake Bradford</Typography>

            {isDesktop ? (
                <Navbar {...props} />
            ) : (
                <HamburgerMenu {...props} />
            )}
        </div>
    )
}

export default Header

