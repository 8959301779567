import React from 'react'
import { Button } from '@mui/material'
import { useStyles } from './styles'

const DownloadButton = ({ entity, path }) => {
    const classes = useStyles()

    return (
        <Button variant={'contained'} className={classes.button}>
            <a href={path} target="_blank" className={classes.link} download rel="noreferrer">
              Download  {entity}
            </a>
        </Button>
    )
}

export default DownloadButton

