import * as colors from './colors'
import * as typography from './typography'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const useThemeSelector = (darkModeOn = true) => {
    let theme = createTheme({
        palette: {
            type: darkModeOn ? 'dark' : 'light',
            error: {
                main: colors.error.main,
                dark: colors.error.dark,
                light: colors.error.light,
            },
            info: {
                main: colors.info.main,
                dark: colors.info.dark,
                light: colors.info.light,
            },
            primary: {
                main: colors.gunMetal.main,
                dark: colors.gunMetal.dark,
                light: colors.gunMetal.light,
            },
            secondary: {
                main: darkModeOn ? colors.platinum.main : colors.gunMetal.main,
                dark: darkModeOn ? colors.platinum.dark : colors.gunMetal.dark,
                light: colors.darkGrey.light,
            },
            success: {
                main: colors.success.main,
                dark: colors.success.dark,
                light: darkModeOn ? colors.success.light : colors.success.lighter,
            },
            text: {
                primary: darkModeOn ? colors.white : colors.black,
                inverted: darkModeOn ? colors.black : colors.white,
                secondary: colors.darkGrey.dark,
                disabled: colors.disabled,
            },
            warning: {
                main: colors.warning.main,
                dark: colors.warning.dark,
                light: darkModeOn ? colors.warning.light : colors.warning.lighter,
            },
            hover: {
                main: darkModeOn ? colors.gunMetal.main : colors.platinum.main,
            },
            selected: {
                main: darkModeOn ? colors.gunMetal.light : colors.platinum.main,
            },
            background: {
                default: darkModeOn ? colors.darkGrey.dark : colors.platinum.dark,
                dark: darkModeOn ? colors.darkGrey.dark : colors.platinum.light,
            },
            icons: {
                main: darkModeOn ? colors.white : colors.darkGrey.main,
                inverted: darkModeOn ? colors.gunMetal.main : colors.gold.dark,
                hover: colors.gunMetal.main,
            },
            iconText: {
                main: darkModeOn ? colors.white : colors.platinum.main,
                dark: darkModeOn ? colors.white : colors.platinum.dark,
                light: darkModeOn ? colors.white : colors.platinum.light,
            },
            link: {
                main: darkModeOn ? colors.info.main : colors.lightPurple.dark,
                visited: darkModeOn ? colors.lightPurple.main : colors.pink.dark,
            },
            switch: {
                background: darkModeOn ? colors.platinum.main : colors.platinum.main,
                icon: darkModeOn ? colors.gunMetal.dark : colors.gold.main,
            },
            buttonHover: {
                main: darkModeOn ? colors.gold.main : colors.gold.main,
            },
            close: {
                main: darkModeOn ? colors.darkGrey.dark : colors.platinum.main,
            },
            tooltip: {
                main: darkModeOn ? colors.platinum.dark : colors.white,
                border: darkModeOn ? colors.platinum.light : colors.platinum.dark,
            },
            divider: darkModeOn ? colors.platinum.light : colors.platinum.dark,
        },
        typography: {
            h1: typography.h1,
            h2: typography.h2,
            h3: typography.h3,
            h4: typography.h4,
            h5: typography.h5,
            body1: typography.p,
            a: typography.p,
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: darkModeOn ? colors.platinum.dark : colors.darkGrey.dark,
                        color: darkModeOn ? colors.white : colors.black,
                        paddingRight: '5px',
                    },
                },
            },
        },
    })
    theme = responsiveFontSizes(theme)

    return {
        theme,
    }
}

export {
    useThemeSelector,
    colors,
    typography,
}
