import { makeStyles } from '@mui/styles'
import { publicImage } from '../../utils'

const responsivePageStyles = () => {
    return (
        {
            justifyContent: (props) => props.isDesktop ? 'center' : 'start',
            alignItems: (props) => props.isDesktop ? 'end' : 'center',
        })
}

const responsiveHomeStyles = () => {
    return (
        {
            backgroundImage: (props) => `url(${publicImage(props.isDesktop ?
                'jake-bradford-transparent-background.png' :
                'jake-bradford-mobile-background.png',
            )})`,
            backgroundSize: (props) => props.isDesktop ? 'cover' : 'cover',
            backgroundPosition: (props) => props.isDesktop ? 'center' : 'center',
            justifyContent: (props) => props.isDesktop ? 'center' : 'start',
        })
}

const responsiveHomeTextStyles = () => {
    return (
        {
            marginTop: (props) => props.isDesktop ? '0' : '15%',
            marginLeft: (props) => props.isDesktop ? '35%' : '0',
        })
}

export const useStyles = makeStyles((theme) => {
    return {
        page: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            backgroundSize: 'cover',
            ...responsivePageStyles(),
        },
        home: {
            fontSize: '25px',
            textAlign: 'center',
            width: '100%',
            minWidth: '300px',
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...responsiveHomeStyles(),
        },
        homeText: {
            color: theme.palette.text.primary,
            ...responsiveHomeTextStyles(),
        },
        title: {
            fontSize: '45px',
        },
        blurb: {
            fontSize: '25px',
        },
    }
})
