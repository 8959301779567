import { makeStyles } from '@mui/styles'
import { colors } from '../../theme'

export const useStyles = makeStyles((theme) => ({
    gallery: {
        minHeight: '50vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: colors.white,
    },
    galleryTitle: {
        color: theme.palette.text.inverted,
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '50vh',
        width: '100%',
        backgroundColor: colors.white,
    },
    imageList: {
        height: '100%',
        margin: '0 2vw 2.5vh',
    },
    imageItem: {
        '& .MuiImageListItem-img': {
            height: '100%',
        },
    },
    image: {
        minWidth: '250px',
        overflow: 'hidden',
    },
}))
