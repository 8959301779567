import React, { useEffect, useState } from 'react'
import VideoCarousel from '../../components/VideoCarousel'
import { Typography, Zoom } from '@mui/material'
import { useStyles } from './styles'
import { fetchJson, useWindowSize } from '../../utils'
import VideoList from '../../components/VideoList'

const Videos = () => {
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })
    const [videos, setVideos] = useState(null)

    useEffect(() => {
        if (videos == null) {
            (async function() {
                const data = await fetchJson('videos.json')

                setVideos(data.videos)
            }())
        }
    }, [videos])

    if (videos == null || videos?.length <= 0 || videos?.error) {
        return (
            <div id='videos' className={classes.videoContainer}>
                <Typography variant='h2'>No videos found</Typography>
            </div>
        )
    }

    // return (
    //     <div id='videos' className={classes.videoContainer}>
    //         <Typography variant='h2' className={classes.galleryTitle}>Videos</Typography>
    //
    //         <Zoom in style={{ transitionDelay: '200ms' }}>
    //             <div id='player' className={classes.content}>
    //                 <VideoCarousel videos={videos} />
    //             </div>
    //         </Zoom>
    //     </div>
    // )

    return (
        <div id='videos' className={classes.videoContainer}>
            <Typography variant='h2' className={classes.galleryTitle}>Videos</Typography>

            <VideoList videos={videos} />
        </div>
    )
}

export default Videos
