import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    navlist: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        marginBottom: '35px',
        cursor: 'auto',
        // padding: '0 20px 20px 20px',
        padding: '0 20px 0 0',
        color: theme.palette.text.inverted,
    },
    navitem: {
        margin: '5px',
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            backgroundColor: '#2D6A4F',
        },
    },
    navtext: {
        margin: '5px',
        minWidth: '50px',
    },
    activeNavItem: {
        margin: '5px',
        color: 'inherit',
        backgroundColor: '#74C69D',
        '&:hover': {
            backgroundColor: '#2D6A4F',
        },
    },
    icon: {
        marginTop: '8px',
        color: theme.palette.icons.inverted,
    },
    hamburgerIcon: {
        color: theme.palette.icons.main,
    },
    closeIcon: {
        color: theme.palette.close.main,
    },
    iconButtonContainer: {
        display: 'flex',
    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        marginTop: '10vh',
    },
    drawer: {
        color: theme.palette.text.inverted,
        height: '100%',
    },
    hamburgerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '15px',
    },
}))
