import { makeStyles } from '@mui/styles'

const responsiveResourceStyles = () => {
    return (
        {
            height: (props) => props.isDesktop ? '90vh' : '100%',
            justifyContent: (props) => props.isDesktop ? 'space-around' : 'space-between',
            flexDirection: (props) => props.isDesktop ? 'row' : 'column',
            padding: (props) => props.isDesktop ? '2.5% 0' : '5% 0',
        })
}

const responsiveImgStyles = () => {
    return (
        {
            paddingBottom: (props) => props.isDesktop ? '0' : '25px',
        })
}

export const useStyles = makeStyles((theme) => ({
    img: {
        height: '100%',
        overflow: 'hidden',
        maxHeight: '85vh',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '85%',
        },
    },
    imgColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        ...responsiveImgStyles(),
    },
    resume: {
        pointerEvents: 'none',
        height: '100%',
        overflow: 'hidden',
        maxHeight: '85vh',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '85%',
        },
    },
    resources: {
        height: '100%',
        minHeight: '90vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        ...responsiveResourceStyles(),
    },
}))

