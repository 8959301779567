import React, { useEffect, useState } from 'react'
import {
    HashRouter as Router,
    Routes,
    Route,
} from 'react-router-dom'
import { StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as LegacyThemeProvider } from '@mui/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useThemeSelector } from './theme'
import Header from './components/Header'
import NotFound from './pages/NotFound'
import Frame from './pages/Frame'

const App = () => {
    const [darkMode, setDarkMode] = useState(true)
    const { theme } = useThemeSelector(darkMode)

    // useEffect(() => {
    //     const storedDarkModeSetting = localStorage.getItem('darkMode') === 'true'
    //
    //     setDarkMode(storedDarkModeSetting)
    // }, [darkMode])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LegacyThemeProvider theme={theme}>
                    <CssBaseline />
                    <Header darkMode={darkMode} setDarkMode={setDarkMode} />
                    <Frame />
                </LegacyThemeProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App
