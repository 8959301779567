import React from 'react'
import { useStyles } from './styles'
import { Fade, Typography } from '@mui/material'
import { publicImage, useWindowSize } from '../../utils'

const Home = () => {
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })

    return (
        <div id='home' className={classes.home}>
            <div className={classes.imageContainer} >
                <img className={classes.image} src={publicImage('jake-transparent2.png')} />
            </div>
            <div id='homeText' className={classes.homeText}>
                <Fade in={true} style={{ transitionDelay: '400ms' }}>
                    <Typography variant='h2' className={classes.title}>Jake Bradford</Typography>
                </Fade>
                <Fade in={true} style={{ transitionDelay: '600ms' }}>
                    <Typography className={classes.blurb}>AEA</Typography>
                </Fade>
                <Fade in={true} style={{ transitionDelay: '600ms' }}>
                    <Typography className={classes.blurb}>Actor - Singer</Typography>
                </Fade>
            </div>
        </div>
    )
}

export default Home
