import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    media: {
        width: '60vw',
        maxHeight: '85vh',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        margin: 'auto',
    },
    button: {
        position: 'absolute',
        top: 15,
        right: 15,
        fontWeight: 900,
        minWidth: 30,
    },
    backButton: {
        position: 'absolute',
        top: '50%',
        left: '10%',
        fontSize: 34,
        cursor: 'pointer',
    },
    description: {
        marginTop: '35px',
    },
    forwardButton: {
        position: 'absolute',
        top: '50%',
        right: '10%',
        fontSize: 34,
        cursor: 'pointer',
    },
}))

