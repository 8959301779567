import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => {
    return {
        page: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'cover',
        },
        title: {
            fontSize: '45px',
            color: theme.palette.text.primary,
        },
        blurb: {
            fontSize: '25px',
            color: theme.palette.text.primary,
        },
    }
})
