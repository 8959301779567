import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => {
    return {
        articles: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '90vh',
            height: '100%',
            width: '100%',
        },
    }
})
