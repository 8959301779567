import React, { useState } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'
import { Typography, SwipeableDrawer, IconButton, Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import DarkModeSwitch from '../DarkModeSwitch'
import { useStyles } from './styles'
import { useScroll } from '../../utils'

const HamburgerMenu = ({ darkMode, setDarkMode }) => {
    const [open, toggleOpen] = useState(false)
    const { scrollTo, elements, inViewport } = useScroll()
    const classes = useStyles()

    const iOS =
  typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

    return (
        <div className={classes.hamburgerContainer}>
            <div className={classes.iconButtonContainer}>
                <IconButton className={classes.iconButton} onClick={() => toggleOpen(!open)} >
                    <MenuIcon className={classes.hamburgerIcon}/>
                </IconButton>
            </div>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onOpen={() => toggleOpen(true)}
                onClose={() => toggleOpen(false)}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                className={classes.drawer}
            >
                <div>
                    <div className={classes.closeButtonContainer}>
                        <IconButton onClick={() => toggleOpen(!open)} >
                            <CloseIcon className={classes.closeIcon}/>
                        </IconButton>

                        {/* <DarkModeSwitch
                            darkMode={darkMode}
                            setDarkMode={setDarkMode}
                        /> */}
                    </div>
                    <div
                        className={classes.navlist}
                    >
                        <Button
                            onClick={() => scrollTo(elements.home, 'start')}
                            className={inViewport.home ? classes.activeNavItem : classes.navitem}
                        >
                            <Typography variant='h3' className={classes.navtext}>Home</Typography>
                        </Button>

                        <Button
                            onClick={() => scrollTo(elements.about, 'start')}
                            className={inViewport.about ? classes.activeNavItem : classes.navitem}
                        >
                            <Typography variant='h3' className={classes.navtext}>About</Typography>
                        </Button>

                        <Button
                            onClick={() => scrollTo(elements.resources, 'start')}
                            className={inViewport.resources ? classes.activeNavItem : classes.navitem}
                        >
                            <Typography variant='h3' className={classes.navtext}>H + R</Typography>
                        </Button>

                        <Button
                            onClick={() => scrollTo(elements.videos, 'start')}
                            className={inViewport.videos ? classes.activeNavItem : classes.navitem}
                        >
                            <Typography variant='h3' className={classes.navtext}>Videos</Typography>
                        </Button>

                        <Button
                            onClick={() => scrollTo(elements.gallery, 'start')}
                            className={inViewport.gallery ? classes.activeNavItem : classes.navitem}
                        >
                            <Typography variant='h3' className={classes.navtext}>Gallery</Typography>
                        </Button>

                        <Button
                            onClick={() => scrollTo(elements.news, 'start')}
                            className={inViewport.news ? classes.activeNavItem : classes.navitem}
                        >
                            <Typography variant='h3' className={classes.navtext}>News</Typography>
                        </Button>
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    )
}

export default HamburgerMenu
