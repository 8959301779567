import { makeStyles } from '@mui/styles'

const responsiveHomeStyles = () => {
    return (
        {
            justifyContent: (props) => props.isDesktop ? 'space-around' : 'space-between',
            flexDirection: (props) => props.isDesktop ? 'row' : 'column-reverse',
            alignItems: (props) => props.isDesktop ? 'end' : 'center',
        })
}

const responsiveHomeTextStyles = () => {
    return (
        {
            height: (props) => props.isDesktop ? '90vh' : '100%',
            marginRight: (props) => props.isDesktop ? '5%' : '0',
        })
}

const responsiveImageStyles = () => {
    return (
        {
            height: (props) => props.isDesktop ? '80vh' : 'unset',
            width: (props) => props.isDesktop ? 'unset' : '80vw',
        })
}

export const useStyles = makeStyles((theme) => {
    return {
        home: {
            textAlign: 'center',
            width: '100%',
            minWidth: '300px',
            minHeight: '90vh',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            ...responsiveHomeStyles(),
        },
        imageContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
        },
        image: {
            height: '80vh',
            ...responsiveImageStyles(),
        },
        homeText: {
            color: theme.palette.text.primary,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '40vh',
            ...responsiveHomeTextStyles(),
        },
        title: {
            fontSize: '4rem',
        },
        blurb: {
            fontSize: '2rem',
        },
    }
})
