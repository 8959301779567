import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    videoContainer: {
        minHeight: '90vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))
