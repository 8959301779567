export const black = '#000000'
export const white = '#FFFFFF'
export const disabled = 'rgba(0, 0, 0, 0.3)'

export const darkGrey = {
    main: '#363636',
    dark: '#1F1F1F',
    light: 'rgba(47, 47, 47, 0.5)',
}

export const gunMetal = {
    main: '#242F40',
    dark: '#242F40',
    light: 'rgba(95, 120, 170, 0.15)',
}

export const gold = {
    main: '#CCA43B',
    dark: '#CCA43B',
    light: 'rgba(230, 240, 100, 0.5)',
}

export const platinum = {
    main: '#E5E5E5',
    dark: '#C2C2C2',
    light: 'rgba(112, 112, 113, 0.5)',
}

// alerts
export const error = {
    main: '#E10606',
    dark: '#B90505',
    light: 'rgba(225, 6, 6, 0.5)',
    lighter: 'rgba(225, 6, 6, 0.35)',
}

export const info = {
    main: '#15A5D2',
    dark: '#1187AC',
    light: 'rgba(21, 165, 210, 0.35)',
}

export const success = {
    main: '#0D9C13',
    dark: '#0B8010',
    light: 'rgba(13, 156, 19, 0.5)',
    lighter: 'rgba(13, 156, 19, 0.35)',
}

export const warning = {
    main: '#FFC700',
    dark: '#D1A300',
    light: 'rgba(255, 199, 0, 0.55)',
    lighter: 'rgba(255, 199, 0, 0.35)',
}

export const lightPurple = {
    main: '#5369D4',
    dark: '#4254AA',
    light: 'rgba(83, 105, 212, 0.15)',
}

export const pink = {
    main: '#CA53D4',
    dark: '#A242AA',
    light: 'rgba(202, 83, 212, 0.15)',
}
