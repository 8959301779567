import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => {
    return {
        navlist: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '40%',
            marginRight: '35px',
            cursor: 'auto',
        },
        navitem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '5px',
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                backgroundColor: '#2D6A4F',
            },
        },
        navtext: {
            margin: '5px',
            minWidth: '50px',
        },
        activeNavItem: {
            margin: '5px',
            color: 'inherit',
            backgroundColor: '#1B4332',
            '&:hover': {
                backgroundColor: '#2D6A4F',
            },
        },
    }
})
