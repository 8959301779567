import { makeStyles } from '@mui/styles'
import { colors } from '../../theme'

export const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: '15px',
    },
    link: {
        textDecoration: 'none',
        color: colors.white,
    },
}))

