import React from 'react'
import { useStyles } from './styles'
import { useWindowSize } from '../../utils'

const YoutubeSlide = ({ url, className }) => {
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })

    return (
        <div className={className}>
            <iframe
                src={url}
                className={classes.iframe}
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    )
}

const VideoList = ({ videos }) => {
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })

    return (
        <div className={classes.videoList}>
            {videos.map((v, i) => (
                <YoutubeSlide
                    url={v.videoUrl}
                    key={i}
                    className={classes.youtube}
                />
            ))}
        </div>
    )
}

export default VideoList
