import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { useScroll } from '../../utils'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { Button } from '@mui/material'

const ScrollToTop = () => {
    const { scrollTo, elements, inViewport, scrolled } = useScroll()
    const [visible, setVisible] = useState('hidden')
    const classes = useStyles()

    useEffect(() => {
        if (!scrolled) {
            setVisible('hidden')
        } else {
            setVisible(inViewport.home ? 'hidden' : 'visible')
        }
    }, [inViewport.home])

    return (
        <>
            <Button onClick={() => scrollTo(elements.home)} sx={{ visibility: visible }} className={classes.button}>
                <KeyboardDoubleArrowUpIcon />
            </Button>
        </>
    )
}

export default ScrollToTop
