/* eslint-disable no-undef */
/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { localImages } from './localImages'

export const publicImage = (path) => {
    return `${process.env.PUBLIC_URL}/${path}`
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window

    return { width, height }
}

export const useWindowSize = (delay = 0) => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    )

    const isDesktop = windowDimensions?.width > 1100

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }

        const debouncedHandleResize = debounce(handleResize, delay)

        window.addEventListener('resize', debouncedHandleResize)

        return () => window.removeEventListener('resize', debouncedHandleResize)
    }, [delay])

    return { isDesktop, ...windowDimensions }
}

export const useScroll = () => {
    const [elements, setElements] = useState({})
    const [inViewport, setInViewport] = useState({})
    const [scrolled, setScrolled] = useState(false)
    const { isDesktop } = useWindowSize()

    useEffect(() => {
        const watchScroll = () => {
            window.addEventListener('scroll', setViewports)
        }

        watchScroll()

        const images = Array.from(document.getElementsByClassName('imageItem'))

        const imagesSlice = isDesktop ? images.slice(4, images.length - 4) : images.slice(1, images.length - 1)

        setElements({
            home: document.getElementById('home'),
            homeText: document.getElementById('homeText'),
            about: document.getElementById('about'),
            aboutImg: document.getElementById('aboutImg'),
            resources: document.getElementById('resources'),
            headshot: document.getElementById('headshot'),
            videos: document.getElementById('videos'),
            iframes: document.getElementsByClassName('iframeVideo'),
            gallery: document.getElementById('gallery'),
            galleryList: imagesSlice,
            news: document.getElementById('news'),
            articles: document.getElementsByClassName('articleCard'),
        })

        setViewports()

        return () => {
            window.removeEventListener('scroll', setViewports)
        }
    }, [])

    const setViewports = () => {
        setScrolled(true)

        setInViewport({
            home: isInViewport(elements.homeText),
            about: isInViewport(elements.aboutImg),
            resources: isInViewport(elements.headshot),
            videos: anyInViewport(elements.iframes ? [...elements.iframes] : []),
            gallery: anyInViewport(elements.galleryList),
            news: anyInViewport(elements.articles ? [...elements.articles] : [], true),
        })
    }

    const scrollTo = (element, block = 'center') => element.scrollIntoView({ behavior: 'smooth', block, inline: 'nearest' })

    return { scrollTo, elements, inViewport, scrolled }
}

export const isInViewport = (element, partiallyVisible = false) => {
    if (element === null || element === undefined) return false

    const { top, left, bottom, right } = element.getBoundingClientRect()

    const adjustedInnerHeight = window.innerHeight * 0.9

    return partiallyVisible ? (
        (top > 0 && top < adjustedInnerHeight) ||
        (bottom > 0 && bottom < adjustedInnerHeight)) &&
        ((left > 0 && left < window.innerWidth) || (right > 0 && right < window.innerWidth)
        ) : (
        top >= 0 &&
        left >= 0 &&
        bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

export const anyInViewport = (elements, partiallyVisible = false) => {
    if (elements === null || elements === undefined || elements.length <= 0) return false

    const results = elements.map((el) => isInViewport(el, partiallyVisible))

    return results.some((res) => res === true)
}

export const fetchJson = async (file) => {
    return await fetch(publicImage(file), {
    // API Endpoint
        method: 'GET', // HTTP Method
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((err) => console.log(`Failed to fetch ${file}: ${err}`)) // Handling error
}

const fetchImgurImages = async (albumID, setImages, setLoading) => {
    const clientID = process.env.REACT_APP_IMGUR_CLIENT_ID
    const auth = `Client-ID ${clientID}`

    await fetch(`https://api.imgur.com/3/album/${albumID}/images`, {
        // API Endpoint
        method: 'GET', // HTTP Method
        headers: {
            // Setting header
            Authorization: auth,
        },
    })
        .then((response) => response.json())
        .then(async (data) => {
            const images = await sortImages(data.data)

            setImages(images)
            setLoading(false)
        }) // Handling success
        .catch((err) => console.log(`Failed to fetch images: ${err}`)) // Handling error
}

const setLocalImages = async (setImages) => {
    const images = await sortImages(localImages)

    setImages(images)
}

export const fetchAboutImages = async (setImages, setLoading) => {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        await setLocalImages(setImages)

        return
    }

    const albumID = process.env.REACT_APP_ABOUT_IMGUR_ALBUM_ID

    await fetchImgurImages(albumID, setImages, setLoading)
}

export const fetchGalleryImages = async (setImages, setLoading) => {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        await setLocalImages(setImages)

        return
    }

    const albumID = process.env.REACT_APP_GALLERY_IMGUR_ALBUM_ID

    await fetchImgurImages(albumID, setImages, setLoading)
}

const sortImages = async (images) => {
    if (!Array.isArray(images) || images.length <= 0) {
        return
    }

    let sorted = []

    const galleryOrder = await fetchJson('galleryOrder.json')
    const order = galleryOrder?.order

    order.map((show) => {
        const showImages = []

        images.map((image) => {
            if (image?.title?.includes(show)) showImages.push(image)
        })

        showImages.sort((a, b) => {
            return extractTitleNumber(a.title) - extractTitleNumber(b.title)
        })

        sorted = sorted.concat(showImages)
    })

    const ungrouped = images.filter((image) => !sorted.includes(image))

    ungrouped.sort((a, b) => {
        return b.title - a.title
    })

    sorted = sorted.concat(ungrouped)

    return sorted
}

const extractTitleNumber = (title) => {
    const numbers = title.match(/\d+/g)

    if (numbers === null || numbers?.length >= 0) {
        return 0
    }

    return parseInt(numbers[numbers?.length - 1])
}
