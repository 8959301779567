import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'
import { Typography, Button } from '@mui/material'
import DarkModeSwitch from '../DarkModeSwitch'
import { useStyles } from './styles'
import { useScroll } from '../../utils'

const Navbar = ({ darkMode, setDarkMode }) => {
    const { scrollTo, elements, inViewport } = useScroll()
    const classes = useStyles()

    return (
        <div className={classes.navlist}>
            <Button
                onClick={() => scrollTo(elements.home)}
                className={inViewport.home ? classes.activeNavItem : classes.navitem}
            >
                <Typography variant='h3' className={classes.navtext}>Home</Typography>
            </Button>

            <Button
                onClick={() => scrollTo(elements.aboutImg)}
                className={inViewport.about ? classes.activeNavItem : classes.navitem}
            >
                <Typography variant='h3' className={classes.navtext}>About</Typography>
            </Button>

            <Button
                onClick={() => scrollTo(elements.resources)}
                className={inViewport.resources ? classes.activeNavItem : classes.navitem}
            >
                <Typography variant='h3' className={classes.navtext}>H + R</Typography>
            </Button>

            <Button
                onClick={() => scrollTo(elements.news, 'start')}
                className={inViewport.news ? classes.activeNavItem : classes.navitem}
            >
                <Typography variant='h3' className={classes.navtext}>News</Typography>
            </Button>

            <Button
                onClick={() => scrollTo(elements.videos, 'start')}
                className={inViewport.videos ? classes.activeNavItem : classes.navitem}
            >
                <Typography variant='h3' className={classes.navtext}>Videos</Typography>
            </Button>

            <Button
                onClick={() => scrollTo(elements.gallery, 'start')}
                className={inViewport.gallery ? classes.activeNavItem : classes.navitem}
            >
                <Typography variant='h3' className={classes.navtext}>Gallery</Typography>
            </Button>

            {/* <DarkModeSwitch
                darkMode={darkMode}
                setDarkMode={setDarkMode}
            /> */}
        </div>
    )
}

export default Navbar

