import { makeStyles } from '@mui/styles'

const responsiveVideoListStyles = () => {
    return (
        {
            paddingBottom: (props) => props.isDesktop ? '2.5%' : '5%',
        })
}

const responsiveYoutubeStyles = () => {
    return (
        {
            margin: (props) => props.isDesktop ? '2.5%' : '5% 0',
        })
}

const responsiveiFrameStyles = () => {
    return (
        {
            width: (props) => window.innerWidth * (props.isDesktop ? 0.3 : 0.8),
        })
}

export const useStyles = makeStyles((theme) => {
    return {
        videoList: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            overflow: 'auto',
            ...responsiveVideoListStyles(),
        },
        youtube: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            ...responsiveYoutubeStyles(),
            '&:nth-child(2n-1):last-of-type': {
                flexGrow: 2,
            },
        },
        iframe: {
            minWidth: '45vw',
            minHeight: '60vh',
            ...responsiveiFrameStyles(),
        },
    }
})

