/* eslint-disable max-len */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react'
import { Card, Typography } from '@mui/material'
import { Slide } from '@mui/material'
import ImageModal from '../../components/ImageModal'
import { useStyles } from './styles'
import { publicImage, useScroll, useWindowSize, fetchAboutImages } from '../../utils'

const About = () => {
    const { inViewport } = useScroll()
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })
    const [transitioned, setTransitioned] = useState(false)
    const [loading, setLoading] = useState(true)
    const [images, setImages] = useState(null)
    const [modalState, setModalState] = useState({ visible: false, image: null })

    const dismissImageModal = () => setModalState({ visible: false, image: null })
    const firstImage = images?.indexOf(modalState.image) === 0
    const lastImage = images?.indexOf(modalState.image) === images?.length - 1
    const nextImage = () => {
        const index = images?.indexOf(modalState.image)

        if (index === images?.length - 1) {
            setModalState({ visible: true, image: images[index] })

            return
        }

        setModalState({ visible: true, image: images[index + 1] })
    }
    const prevImage = () => {
        const index = images?.indexOf(modalState.image)

        if (index === 0) {
            setModalState({ visible: true, image: images[index] })

            return
        }

        setModalState({ visible: true, image: images[index - 1] })
    }

    useEffect(() => {
        if (images == null) {
            (async function() {
                await fetchAboutImages(setImages, setLoading)
            }())
        }

        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [images, loading])

    // useEffect(() => {
    //     if (inViewport.about) {
    //         setTimeout(() => {
    //             setTransitioned(true)
    //         }, 200)
    //     }
    // }, [inViewport.about])

    return (
        <div id='about' className={classes.aboutContainer}>
            <ImageModal
                visible={modalState.visible}
                image={modalState.image}
                dismissImageModal={dismissImageModal}
                firstImage={firstImage}
                lastImage={lastImage}
                nextImage={nextImage}
                prevImage={prevImage}
            />

            <div className={classes.aboutTextContainer}>
                {/*             <Slide direction='up' in={transitioned}> */}
                <img id='aboutImg' className={classes.img} src={publicImage('jake-bradford-cabaret.jpg')}/>
                {/*             </Slide> */}
                {/*             <Slide direction='left' in={transitioned}> */}
                <Card className={classes.about}>
                    <Typography variant='h1'>Hello!</Typography>
                    <Typography>
                        {`Jake Bradford is from San Diego, California where he grew up surrounded by the arts. He attended the Vista Academy of Visual and Performing Arts, where he fell in love with theatre and performing. He performed professionally at theaters and theme parks all over San Diego and Southern California throughout high school and college, eventually earning a BA in Theatre from UC San Diego in 2021. At UCSD, he studied acting under Jennifer Chang, Natalie Robichaux, and Kim Rubinstein. 

In his free time, Jake loves video games, Mexican food, Dungeons & Dragons, and the San Diego Padres. His brothers, Drew and Josh, are also actors and he loves performing with the both of them.

Jake was most recently seen as Bobby C. in Saturday Night Fever and Younger Brother in Ragtime at Moonlight Stage Productions in Vista, CA. He was also seen as Jean-Michel in La Cage aux Folles at Cygnet Theatre in Old Town San Diego, CA.`}
                    </Typography>
                </Card>
            </div>
            <div className={classes.imageList}>
                {images?.map((item, i) => (
                    <img
                        key={i}
                        srcSet={item.link}
                        src={item.link}
                        alt={item.title}
                        loading="lazy"
                        className={`${classes.image} imageItem`}
                        onClick={() => setModalState({ visible: true, image: item })}
                    />
                ))}
            </div>
            {/*             </Slide> */}
        </div>
    )
}

export default About
