export const h1 = {
    fontFamily: 'Dosis, sans-serif',
    fontWeight: 700,
    fontSize: 36,
    textTransform: 'initial',
    letterSpacing: -1.5,
    margin: '25px',
}

export const h2 = {
    fontFamily: 'Dosis, sans-serif',
    fontWeight: 600,
    fontSize: 36,
    textTransform: 'initial',
    letterSpacing: -0.5,
    margin: '25px',
}

export const h3 = {
    fontFamily: 'Dosis, sans-serif',
    fontWeight: 400,
    fontSize: 20,
    textTransform: 'initial',
    letterSpacing: 0,
}

export const h4 = {
    fontFamily: 'Dosis, sans-serif',
    fontWeight: 300,
    fontSize: 18,
    textTransform: 'uppercase',
    letterSpacing: 0.25,
}

export const h5 = {
    fontFamily: 'Dosis, sans-serif',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'initial',
    letterSpacing: 0.15,
}

export const p = {
    fontFamily: 'Dosis, sans-serif',
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'initial',
    letterSpacing: 0.15,
}

