import { makeStyles } from '@mui/styles'
import { colors } from '../../theme'

const responsiveAboutContainerStyles = () => {
    return (
        {
            justifyContent: (props) => props.isDesktop ? 'space-around' : 'center',
        })
}

const responsiveAboutTextContainerStyles = () => {
    return (
        {
            flexDirection: (props) => props.isDesktop ? 'row' : 'column',
            // padding: (props) => props.isDesktop ? '10% 0' : '25px',
            justifyContent: (props) => props.isDesktop ? 'space-around' : 'center',
        })
}

const responsiveAboutStyles = () => {
    return (
        {
            height: (props) => props.isDesktop ? '40%' : '80%',
            width: (props) => props.isDesktop ? '40%' : '80%',
        })
}

const responsiveImgStyles = () => {
    return (
        {
            width: (props) => props.isDesktop ? '40%' : '80%',
            marginBottom: (props) => props.isDesktop ? '0' : '25px',
        })
}

const responsiveImageStyles = () => {
    return (
        {
            minWidth: (props) => props.isDesktop ? '33.3vw' : '50vw',
            marginBottom: (props) => props.isDesktop ? '0' : '25px',
        })
}

export const useStyles = makeStyles((theme) => ({
    aboutContainer: {
        minHeight: '90vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.secondary.main,
        ...responsiveAboutContainerStyles(),
    },
    aboutTextContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
        padding: '5% 0',
        ...responsiveAboutTextContainerStyles(),
    },
    about: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        padding: '15px',
        borderRadius: '10px',
        minHeight: '500px',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.main,
        ...responsiveAboutStyles(),
    },
    img: {
        overflow: 'hidden',
        borderRadius: '10px',
        ...responsiveImgStyles(),
    },
    image: {
        overflow: 'hidden',
        cursor: 'pointer',
        objectFit: 'cover',
        ...responsiveImageStyles(),
    },
    imageList: {
        display: 'flex',
        overflowY: 'scroll',
        height: '40vh',
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
}))
