import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => {
    return {
        switch: {
            '&.MuiSwitch-root': {
                overflow: 'visible',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            '& .MuiSwitch-switchBase': {
                backgroundColor: theme.palette.switch.background,
                color: theme.palette.switch.icon,
                height: '30px',
                width: '30px',
                marginTop: '2px',
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                backgroundColor: theme.palette.switch.background,
                color: theme.palette.switch.icon,
                height: '30px',
                width: '30px',
                marginTop: '2px',
                marginLeft: '4px',
            },
        },
    }
})
