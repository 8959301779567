import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '10vh',
        color: theme.palette.text.primary,
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: 9999,
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
}))
