import React from 'react'
import Modal from 'react-modal'
import { CardMedia, Button, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useStyles } from './styles'

export const ImageModal = ({
    image, firstImage, lastImage, nextImage,
    prevImage, visible, dismissImageModal,
}) => {
    Modal.setAppElement('#root')

    const customStyles = {
        overlay: {
            zIndex: 999999999999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-25%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            border: 'unset',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }

    return (
        <ImageModalView
            visible={visible}
            dismissImageModal={dismissImageModal}
            firstImage={firstImage}
            lastImage={lastImage}
            nextImage={nextImage}
            prevImage={prevImage}
            customStyles={customStyles}
            image={image}
        />
    )
}

export const ImageModalView = ({ visible, dismissImageModal, firstImage, lastImage, nextImage,
    prevImage, customStyles, image }) => {
    const classes = useStyles()

    return (
        <Modal
            isOpen={visible}
            onRequestClose={() => dismissImageModal()}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Button
                variant='contained'
                color='secondary'
                onClick={() => dismissImageModal()}
                className={classes.button}
            >
                X
            </Button>
            <div className={classes.imgRow}>
                {!firstImage &&
                    <ArrowBackIosIcon
                        onClick={prevImage}
                        className={classes.backButton}
                    />
                }
                <CardMedia
                    component='img'
                    alt={image?.alt}
                    image={image?.link}
                    title={image?.title}
                    className={classes.media}
                />
                {!lastImage &&
                    <ArrowForwardIosIcon
                        onClick={nextImage}
                        className={classes.forwardButton}
                    />
                }
            </div>
            <Typography className={classes.description} variant='h3'>{image?.description}</Typography>
        </Modal>
    )
}

export default ImageModal

