import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => {
    return {
        button: {
            position: 'fixed',
            bottom: '2.5%',
            left: '2.5%',
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: '#95D5B2',
                color: theme.palette.text.inverted,
            },
        },
    }
})

