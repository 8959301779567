import { makeStyles } from '@mui/styles'

const responsiveListStyles = () => {
    return (
        {
            width: (props) => props.isDesktop ? '60%' : '80%',
        })
}

export const useStyles = makeStyles((theme) => {
    return {
        articles: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        listItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: theme.palette.text.inverted,
            backgroundColor: theme.palette.background.default,
            margin: '35px 0',
            boxShadow: 'black 0px 10px 30px 0px',
            borderRadius: '15px',
            padding: '20px',
            ...responsiveListStyles(),
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '15px',
            whiteSpace: 'break-spaces',
            backgroundColor: theme.palette.icons.main,
            borderRadius: '15px',
        },
        title: {
            margin: '10px',
        },
        img: {
            objectFit: 'scale-down',
            maxHeight: '50vh',
            backgroundColor: theme.palette.background.default,
            marginBottom: '10px',
        },
        button: {
            margin: '10px',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: theme.palette.buttonHover.main,
                transform: `scale(1.2)`,
            },
        },
    }
})
