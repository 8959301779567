import React from 'react'
import { useStyles } from './styles'
import DownloadButton from '../../components/DownloadButton'
import { Grow } from '@mui/material'
import { publicImage, useWindowSize } from '../../utils'

const Resources = () => {
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })

    return (
        <div id='resources' className={classes.resources}>
            <Grow in style={{ transformOrigin: 'bottom', transitionDelay: '200ms' }}>
                <div id='headshot' className={classes.imgColumn}>
                    <img className={classes.img} src={publicImage('jake-bradford-headshot.jpg')} />
                    <DownloadButton entity={'Headshot'} path={publicImage('jake-bradford-headshot.jpg')} />
                </div>
            </Grow>
            <Grow in style={{ transformOrigin: 'bottom', transitionDelay: '200ms' }}>
                <div className={classes.imgColumn}>
                    <img className={classes.resume} src={publicImage('jake-bradford-resume-07-24.png')} />
                    <DownloadButton entity={'Resume'} path={publicImage('jake-bradford-resume-07-24.pdf')} />
                </div>
            </Grow>
        </div>
    )
}

export default Resources
