import { useEffect, useState } from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageModal from '../../components/ImageModal'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material'
import { useStyles } from './styles'
import { useWindowSize, fetchGalleryImages } from '../../utils'

const Gallery = () => {
    const [loading, setLoading] = useState(true)
    const [images, setImages] = useState(null)
    const [modalState, setModalState] = useState({ visible: false, image: null, images: null })
    const { width } = useWindowSize()
    const classes = useStyles()

    const setColumns = (width) => {
        if (width > 1400) {
            return 4
        } else if (width <= 1400 && width >= 980) {
            return 3
        } else if (width < 980 && width > 650) {
            return 2
        } else {
            return 1
        }
    }

    const dismissImageModal = () => setModalState({ visible: false, image: null, images })
    const firstImage = images?.indexOf(modalState.image) === 0
    const lastImage = images?.indexOf(modalState.image) === images?.length - 1
    const nextImage = () => {
        const index = images?.indexOf(modalState.image)

        if (index === images?.length - 1) {
            setModalState({ visible: true, image: images[index] })

            return
        }

        setModalState({ visible: true, image: images[index + 1] })
    }
    const prevImage = () => {
        const index = images?.indexOf(modalState.image)

        if (index === 0) {
            setModalState({ visible: true, image: images[index] })

            return
        }

        setModalState({ visible: true, image: images[index - 1] })
    }

    useEffect(() => {
        if (images == null) {
            (async function() {
                await fetchGalleryImages(setImages, setLoading)
            }())
        }

        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [images, loading])

    if (loading) {
        return (
            <div id='gallery' className={classes.loading}>
                <CircularProgress size={200}/>
            </div>
        )
    }

    if (images == null || images?.length <= 0 || images?.error) {
        return (
            <div className={classes.gallery}>
                <Typography variant='h2'>No images found</Typography>
            </div>
        )
    }

    return (
        <div id='gallery' className={classes.gallery}>
            <ImageModal
                visible={modalState.visible}
                image={modalState.image}
                dismissImageModal={dismissImageModal}
                firstImage={firstImage}
                lastImage={lastImage}
                nextImage={nextImage}
                prevImage={prevImage}
            />

            <Typography variant='h2' className={classes.galleryTitle}>Gallery</Typography>

            <ImageList cols={setColumns(width)} gap={15} rowHeight={300} className={classes.imageList}>
                {images?.map((item, i) => (
                    <ImageListItem key={i} className={classes.imageItem}>
                        <img
                            srcSet={item.link}
                            src={item.link}
                            alt={item.title}
                            loading="lazy"
                            className={`${classes.image} imageItem`}
                            onClick={() => setModalState({ visible: true, image: item, images })}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    )
}

export default Gallery
