import React from 'react'
import { useStyles } from './styles'
import { Typography, Card, CardMedia, CardContent, Button } from '@mui/material'
import { useWindowSize } from '../../utils'

const ArticleList = ({ articles }) => {
    return (
        <>
            {articles.map(({ title, desc, img, url, btn_text }, i) => {
                return (
                    <ArticleListItem
                        key={i}
                        title={title}
                        desc={desc}
                        img={img}
                        url={url}
                        btn_text={btn_text}
                    />
                )
            })}
        </>
    )
}

const ArticleListItem = ({ title, desc, img, url, btn_text = 'More Info' }) => {
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })

    return (
        <Card className={`${classes.listItem} articleCard`}>
            <CardMedia component='img' image={img} className={classes.img}/>

            <CardContent className={classes.textContainer}>
                <Typography variant='h5' className={classes.title}>{title}</Typography>
                {url &&
                    <Button variant={'contained'} className={classes.button} component={'a'} href={url} target="_blank" rel="noreferrer">
                        {btn_text}
                    </Button>
                }
                <Typography>{desc}</Typography>
            </CardContent>
        </Card>
    )
}

export default ArticleList
