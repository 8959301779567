import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import ArticleList from '../../components/ArticleList'
import { fetchJson } from '../../utils'
import { Typography } from '@mui/material'

const News = () => {
    const classes = useStyles()
    const [articles, setArticles] = useState(null)

    useEffect(() => {
        if (articles == null) {
            (async function() {
                const data = await fetchJson('articles.json')

                setArticles(data.articles)
            }())
        }
    }, [articles])

    if (articles == null || articles?.length <= 0 || articles?.error) {
        return (
            <div id='news' className={classes.articles}>
                <Typography variant='h2'>No articles found</Typography>
            </div>
        )
    }

    return (
        <div id='news' className={classes.articles}>
            <Typography variant='h2'>News</Typography>
            <ArticleList articles={articles}/>
        </div>
    )
}

export default News
