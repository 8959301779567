import React from 'react'
import { useStyles } from './styles'
import { Divider } from '@mui/material'
import { useWindowSize } from '../../utils'
import About from '../About'
import Resources from '../Resources'
import Videos from '../Videos'
import Gallery from '../Gallery'
import News from '../News'
import Home from '../Home'
import ScrollToTop from '../../components/ScrollToTop'

const Frame = () => {
    const { isDesktop } = useWindowSize()
    const classes = useStyles({ isDesktop })

    return (
        <div className={classes.page}>
            <Home />
            <About />
            <Resources />
            <Divider sx={{ border: '5vh solid #74C69D', width: '100%' }} />
            <News />
            <Divider sx={{ border: '5vh solid #1B4332', width: '100%' }} />
            <Videos />
            <Divider sx={{ border: '5vh solid #40916C', width: '100%' }} />
            <Gallery />
            <ScrollToTop />
        </div>
    )
}

export default Frame
